const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 3,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  title: {
    mb: 1,
    color: 'neutral.black',
  },
  text: {
    color: 'neutral.dark',
  },
  wrapBankCard: {
    display: 'flex',
    justifyContent: 'center',
    my: 2,
  },
  wrapActions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  //
  cta: {},
}

export default styles
