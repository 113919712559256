import { useTexts } from '../../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../../forms'
import { Auth } from '../../../../../business'

const getFormValidationConfig = (texts, emailValidation) => {
  const { isValidEmail, isFilled } = validators

  const emailValidators = [
    [isFilled, texts.getErrorRequired()],
    [isValidEmail, texts.getErrorEmail()],
  ]
  if (!!emailValidation && !!emailValidation?.regExpPattern) {
    const customValidator = (val) =>
      new RegExp(
        emailValidation.regExpPattern,
        emailValidation?.regExpFlags ?? undefined,
      ).test(val)
    emailValidators.push([
      customValidator,
      emailValidation?.message ?? texts.getErrorEmail(),
    ])
  }

  return { email: composeValidators(emailValidators) }
}

const useEmailForm = (formId, emailValidation) => {
  const texts = useTexts()

  const formValidationConfig = getFormValidationConfig(texts, emailValidation)

  const getIsNewUser = async (formValues) => {
    const registrationTypologyForEmail =
      await Auth.getRegistrationTypologyForEmail(formValues.email)
    if (registrationTypologyForEmail === Auth.REGISTRATION_TYPOLOGY.STANDARD) {
      return { isNewUser: false }
    } else if (
      registrationTypologyForEmail === Auth.REGISTRATION_TYPOLOGY.IDP
    ) {
      throw new Error(texts.getAccessWithSocialLoginEmailError())
    } else {
      return { isNewUser: true }
    }
  }

  return useFormV2(formId, getIsNewUser, formValidationConfig)
}

export default useEmailForm
