import theme from '../../../../theme'

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: {
      xs: 2.5,
      md: 3.5,
    },
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
  },
  sectionTitle: {
    px: {
      xs: 0.25,
      lg: 0.5,
    },
    ...theme.typography.h5,
    color: 'neutral.black',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    rowGap: 1.5,
    columnGap: {
      xs: 1,
      md: 2,
    },
    my: {
      xs: 2,
      md: 2,
    },
  },
  balanceDescription: {
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    mx: { xs: 'auto', md: 0 },
    '& > p': {
      mb: 0.5,
    },
  },
}

export default styles
