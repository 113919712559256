import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { Icons } from '../../../../assets'
import { Session } from '../../../../business/index.js'
import { useTexts } from '../../../../texts/index.js'
import { Card } from '../../../components'
import * as PT from '../../../propTypes'

const styles = {
  communicationsCopy: {
    '& > svg': {
      fontSize: (theme) => theme.spacing(1.2),
      transform: 'translate(0, 4px)',
      mr: '3px',
    },
  },
}

const falsyToEmptyString = (value) => (Boolean(value) ? value : '')

const personalDataCardPropTypes = {
  userData: PT.UserData,
  onClickUpdatePassword: PropTypes.func,
  onClickUpdatePersonalData: PropTypes.func,
}

const PersonalDataCard = ({
  userData,
  onClickUpdatePassword,
  onClickUpdatePersonalData,
}) => {
  const texts = useTexts()

  const title = `${falsyToEmptyString(userData.name)} ${falsyToEmptyString(
    userData.familyName,
  )}`

  const actions = {
    primary: {
      label: texts.updatePersonalData(),
      onClick: onClickUpdatePersonalData,
    },
  }
  if (!Session.getIsIdpAccount()) {
    actions.secondary = {
      label: texts.updatePassword(),
      onClick: onClickUpdatePassword,
    }
  }

  const showIncompleteDataError = !userData?.name || !userData?.familyName

  return (
    <Card
      type='user'
      variant={showIncompleteDataError ? 'light_error' : 'light'}
      title={title}
      actions={actions}
      renderContent={() => (
        <>
          <Box>
            <Typography>{userData.email}</Typography>
            <Typography>{userData.phone}</Typography>
          </Box>

          <Typography sx={styles.communicationsCopy}>
            {userData.communicationsAccepted ? (
              <>
                <Icons.Check color='#75D7A6' />
                {texts.communicationsAgreementAcepted()}
              </>
            ) : (
              <>
                <Icons.Close color='#E76245' />
                {texts.communicationsAgreementNotAcepted()}
              </>
            )}
          </Typography>
          {showIncompleteDataError && (
            <Typography className='highlighted'>
              {texts.incompleteData()}
            </Typography>
          )}
        </>
      )}
    />
  )
}

PersonalDataCard.propTypes = personalDataCardPropTypes

export default PersonalDataCard
