import { User } from '../../../business'

const PrivateDataProvider = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>
          <User.BonusProvider>{children}</User.BonusProvider>
        </User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default PrivateDataProvider
