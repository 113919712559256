import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Box } from '@mui/material'

import { Chip } from '../../../../components'
import * as PT from '../../../../propTypes'
import { PageBlock } from '../../../../Layout'
import { useTexts } from '../../../../../texts'

const styles = {
  buttonsContainer: {
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    p: 0,

    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: 0.5, md: 1 },
  },
}

const InterestsViewPropTypes = {
  interests: PropTypes.arrayOf(PT.Topic).isRequired,
  onClickTopic: PropTypes.func.isRequired,
  onRenderInterests: PropTypes.func.isRequired,
}

const InterestsView = ({ interests, onClickTopic, onRenderInterests }) => {
  const texts = useTexts()

  useEffect(() => {
    onRenderInterests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBlock title={texts.getInterestsTitle()}>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {interests.map((interest) => (
          <Chip
            key={interest.id}
            text={
              Boolean(interest.emoji)
                ? `${interest.emoji} ${interest.name}`
                : interest.name
            }
            variant='inverted'
            onClick={() => onClickTopic(interest)}
          />
        ))}
      </Box>
    </PageBlock>
  )
}
InterestsView.propTypes = InterestsViewPropTypes

export default InterestsView
