import { Box } from '@mui/material'

import { AssociatedMedia } from '../../components'
import useMediaButtons from '../useMediaButtons'

const styles = {
  root: {
    mt: 'var(--spacing-xl)',
    px: { xs: 'var(--spacing-l)', md: 0 },
    mx: 'auto',
  },
}

const Medias = () => {
  const { media, onClickMedia } = useMediaButtons()

  return (
    <Box sx={styles.root}>
      <AssociatedMedia media={media} onClickMedia={onClickMedia} />
    </Box>
  )
}

export default Medias
