import PropTypes from 'prop-types'

import * as PT from '../../../propTypes'
import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import UserDataStep from './UserDataStep'
import { EMAIL_STEP_VARIANT } from './EmailStep/View'

// NOTE: This component is used both for Access ('/access') and OnboardingAccess ('/onboarding-access') flows

const acessStepsPropTypes = {
  variant: PropTypes.oneOf(Object.values(EMAIL_STEP_VARIANT)),
  partner: PT.Partner,
}

const AccessSteps = ({
  variant,
  partner,
  registerEmail,
  loginEmail,
  resetAccess,
  handleSubmitEmail,
  handleFinishAuth,
  attribution,
  handleGoToForgottenPassword,
}) => (
  <>
    {Boolean(registerEmail) ? (
      <UserDataStep
        email={registerEmail}
        onChangeEmail={resetAccess}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: true })
        }
        attribution={attribution}
      />
    ) : Boolean(loginEmail) ? (
      <PasswordStep
        email={loginEmail}
        onGoToForgottenPassword={handleGoToForgottenPassword}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: false })
        }
      />
    ) : (
      <EmailStep
        variant={variant}
        partner={partner}
        onSubmitEmail={handleSubmitEmail}
      />
    )}
  </>
)
AccessSteps.propTypes = acessStepsPropTypes

export default AccessSteps
