import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'

import { useTexts } from '../../../../../texts'
import { CreditCard } from '../../../../components'
import { Icons } from '../../../../../assets'
import ScrollToTop from '../../../useScrollToTop'
import styles from './successView.styles'

const View = ({ title, subtitle, bankCard }) => {
  const texts = useTexts()

  if (bankCard.isLoading) return <CircularProgress />
  return (
    <ScrollToTop>
      <Box sx={styles.root}>
        <Box sx={styles.content}>
          <Typography variant='subtitle1' sx={styles.title}>
            {title}
          </Typography>
          <Typography sx={styles.text}>{subtitle}</Typography>

          <Box sx={styles.wrapBankCard}>
            <CreditCard bankCard={bankCard.data} />
          </Box>

          <Box sx={styles.wrapActions}>
            <Button
              fullWidth
              color='secondary'
              startIcon={<Icons.Coins1 />}
              component={Link}
              to='/account/top-up-balance'
              sx={styles.cta}
            >
              {texts.getBalanceTopUpActionLong()}
            </Button>
            <Button
              fullWidth
              startIcon={<Icons.ArrowLeft />}
              component={Link}
              to='..'
              sx={styles.altCta}
            >
              {texts.getGoBack()}
            </Button>
          </Box>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default View
