import { useNavigate } from 'react-router-dom'

import EVENTS_NAMES from '../../../../../analytics/eventsNames.json'
import View from './View'

const Interests = ({ partnerData }) => {
  const navigate = useNavigate()
  const interests = partnerData?.data?.topics

  const handleClickTopic = (topic) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_TOPIC_OF_INTEREST_CLICK,
          topicId: topic.id,
        },
      }),
    )
    navigate(`topic/${topic.id}/${encodeURIComponent(topic.name)}`, {
      state: {
        topicName: `${Boolean(topic.emoji) ? topic.emoji + ' ' : ''}${
          topic.name
        }`,
      },
    })
  }

  const handleRenderInterests = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_TOPIC_OF_INTEREST_SHOW,
        },
      }),
    )
  }

  if (partnerData.isLoading || !Boolean(interests?.length)) {
    return null
  }
  return (
    <View
      interests={interests}
      onClickTopic={handleClickTopic}
      onRenderInterests={handleRenderInterests}
    />
  )
}

export default Interests
