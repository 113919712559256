import { createContext, useContext, useState, useEffect } from 'react'

import { User, Public } from '../index'

const BonusContext = createContext(null)

export const useBonus = () => {
  const context = useContext(BonusContext)
  if (context === null) {
    throw new Error('useBonus must be used within a BonusProvider')
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const BonusProvider = ({ children }) => {
  const [bonusState, setBonusState] = useState(initialState)
  const [partnerState, setPartnerState] = useState(initialState)

  const fetchActiveBonus = async () => {
    try {
      const bonus = await User.fetchActiveBonus()
      setBonusState({
        isLoading: false,
        data: bonus,
        error: false,
      })
    } catch (error) {
      setBonusState({ isLoading: false, data: null, error })
    }
  }

  const fetchActivePartner = async (partnerId) => {
    try {
      const partner = await Public.getPartnerById(partnerId)
      setPartnerState({
        isLoading: false,
        data: partner,
        error: false,
      })
    } catch (error) {
      setPartnerState({ isLoading: false, data: null, error })
    }
  }

  useEffect(() => {
    ;(async () => await fetchActiveBonus())()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        Boolean(bonusState?.data) &&
        Boolean(bonusState?.data?.detail?.partnerId)
      ) {
        await fetchActivePartner(bonusState.data.detail.partnerId)
      }
    })()
  }, [bonusState])

  return (
    <BonusContext.Provider value={{ bonusState, partnerState }}>
      {children}
    </BonusContext.Provider>
  )
}

export default BonusProvider
