import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import { AssociatedMediaData } from '../../propTypes'
import { MediaButton } from '..'
import styles from './associatedMedia.styles'

const associatedMediaPropTypes = {
  media: PropTypes.arrayOf(AssociatedMediaData),
  onClickMedia: PropTypes.func,
  onClickShowAll: PropTypes.func,
}

const AssociatedMedia = ({ media = [], onClickMedia, onClickShowAll }) => {
  const texts = useTexts()
  return (
    <Box sx={styles.root} id='associated_media'>
      <Box sx={styles.buttonsContainer}>
        {media.map((mediaData, i) => (
          <MediaButton
            key={mediaData?.id ?? i}
            onClick={onClickMedia}
            data={mediaData}
          />
        ))}
      </Box>
      {Boolean(onClickShowAll) && (
        <Button fullWidth variant='outlined' onClick={onClickShowAll}>
          {texts.showAllMedias()}
        </Button>
      )}
    </Box>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
