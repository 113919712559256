import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import { User, Public } from '../../../business'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import NavigationSecondary from './NavigationSecondary'
import styles from './secondaryPage.styles'

const TITLE_PATH_MAP = {
  newspapers: (texts) => texts.newspapers(),
  'top-up-balance': (texts) => texts.getBalanceTopUpLabel(),
  'bank-card-edition': (texts) => texts.getBankCardEditLabel(),
  'bank-card-introduction': (texts) => texts.getBankCardAddLabel(),
  'update-personal-data': (texts) => texts.updatePersonalData(),
  'update-password': (texts) => texts.updatePassword(),
}

const NAVIGATION_ID = 'secondary-navigation'
const CONTENT_ID = 'main-secondary-page'

const DataProviders = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>{children}</User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export const SecondaryPageLayoutView = ({ title, onClickGoBack, children }) => (
  <ScrollRevisionOnPageChange>
    <Box sx={styles.root}>
      <NavigationSecondary
        id={NAVIGATION_ID}
        title={title}
        onClickGoBack={onClickGoBack}
      />
      <Box component='main' sx={styles.main} id={CONTENT_ID}>
        {children}
      </Box>
    </Box>
  </ScrollRevisionOnPageChange>
)

const getTitleOnLocation = (location, texts, sections, params) => {
  if (location.pathname.includes('section')) {
    if (!!sections?.data) {
      return sections.data[params.sectionId]
    }
  } else if (location.pathname.includes('topic')) {
    return location?.state?.topicName ?? decodeURIComponent(params.topic)
  } else {
    const pathName = Object.keys(TITLE_PATH_MAP).find((path) =>
      location.pathname.includes(path),
    )
    if (pathName) {
      return TITLE_PATH_MAP[pathName](texts)
    }
  }
  return ''
}

const SecondaryPageLayout = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const texts = useTexts()
  const sections = Public.useArticlesSections()

  const title = getTitleOnLocation(location, texts, sections, params)

  const handleGoBack = () => {
    navigate(`/${location.pathname.split('/')[1]}`)
  }

  return (
    <DataProviders>
      <SecondaryPageLayoutView title={title} onClickGoBack={handleGoBack}>
        {children}
      </SecondaryPageLayoutView>
    </DataProviders>
  )
}

export default SecondaryPageLayout
