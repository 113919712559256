import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Alert, TopUpSelectorCards } from '../../../components'

const AMOUNT_OPTIONS = [1, 2, 5]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]
const DEFAULT_OPTION = AMOUNT_OPTIONS[0]

const topUpBalancePropTypes = {
  bankCardData: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
  onClickTopUp: PropTypes.func,
  isRequestingUpdate: PropTypes.bool,
  messageErrorOnRequest: PropTypes.string,
}

const View = ({ onClickTopUp, isRequestingUpdate, messageErrorOnRequest }) => {
  const texts = useTexts()
  const [selected, setSelected] = useState(DEFAULT_OPTION)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    onClickTopUp(selected)
  }

  return (
    <Box className='secondary-page-content' sx={{ gap: 2 }}>
      <Box
        component='form'
        id='amount'
        onSubmit={handleSubmit}
        sx={{ mt: 'var(--spacing-l)' }}
      >
        <TopUpSelectorCards
          options={AMOUNT_OPTIONS}
          popularOption={POPULAR_OPTION}
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
      {Boolean(messageErrorOnRequest) && (
        <Alert variant='error' withContactLink text={messageErrorOnRequest} />
      )}

      <Button
        fullWidth
        color='primary'
        type='submit'
        form='amount'
        disabled={Boolean(messageErrorOnRequest) && !isRequestingUpdate}
      >
        {texts.getBalanceTopUpLabel()}
      </Button>
    </Box>
  )
}

View.propTypes = topUpBalancePropTypes

export default View
