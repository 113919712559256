const styles = {
  root: (theme) => ({
    width: `min(100%, var(--content-L-max-width))`,
    mx: 'auto',
    px: (theme) => ({
      xs: theme.spacing(0),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),

    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',
  }),
  buttonsContainer: (theme) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
    },
  }),
}

export default styles
