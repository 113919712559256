import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { isValidUrl } from '../../../../forms/validators'
import { User, Public } from '../../../../business'
import { openMediaLink } from '../../../utils'
import { ArticlesList, CircularProgress } from '../../../components'
import View from './View'

const SearchResults = ({ searchValue, onClickArticle }) => {
  const fetchArticlesBySearch = useMemo(
    () => async (pagination) => {
      if (isValidUrl(searchValue)) {
        const articleData = await Public.getArticleDataByUrl(searchValue)
        if (articleData !== null) {
          onClickArticle(articleData, '/discovery')
          return []
        }
      }
      return await User.getArticlesBySearch(searchValue, pagination)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue],
  )

  const articlesData = User.useArticlesList(
    { fetchFn: fetchArticlesBySearch, withLastPageValidationHack: true },
    searchValue,
  )

  return (
    <ArticlesList
      name='search'
      onClickArticle={onClickArticle}
      {...articlesData}
    />
  )
}

const Search = () => {
  const navigate = useNavigate()
  const { searchValue: encodedSearchValue } = useParams()
  const searchValue = decodeURIComponent(encodedSearchValue)

  const [isRedirectingToArtice, setIsRedirectingToArticle] = useState(false)

  const navigateToDiscovery = () => {
    navigate('..')
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formValues = Object.fromEntries(new FormData(ev.target))
    const searchInputValue = formValues.search

    if (Boolean(searchInputValue)) {
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PD_SEARCH,
            text: searchInputValue,
          },
        }),
      )
      navigate(`/discovery/search/${encodeURIComponent(searchInputValue)}`)
    }
  }

  const handleOpenArticle = (article, goBackUrl) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          interface: 'search',
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )
    setIsRedirectingToArticle(true)
    openMediaLink(article.url, goBackUrl ?? document.location.pathname)
  }

  if (isRedirectingToArtice) return <CircularProgress height='80vh' />
  return (
    <View
      initialInputValue={searchValue ?? ''}
      onCancelSearch={navigateToDiscovery}
      onSubmitSearch={handleSubmit}
      renderResults={() =>
        searchValue && (
          <SearchResults
            searchValue={searchValue}
            onClickArticle={handleOpenArticle}
          />
        )
      }
    />
  )
}

export default Search
