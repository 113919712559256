import theme from '../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-m)',
  },
  fieldset: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: { xs: 0.5, md: 1 },
    p: 0,
    m: 0,
    border: 0,
  },
  option: {
    flex: 1,
    display: 'grid',
    gridTemplateRows: (theme) => `${theme.spacing(0.625)} auto`,
    rowGap: 0.5,
    pt: 0.75,
    pb: 2,
    px: 0.75,
    color: 'neutral.dark',
    textAlign: 'center',
    border: `1px solid ${theme.palette.neutral.light}`,
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 3,
    backgroundColor: 'neutral.white',
    position: 'relative',

    '&:has(input:checked)': {
      boxShadow: 1,
      outline: (theme) =>
        `max(2px, 0.08em) solid ${theme.palette.primary.main}`,

      '&:focus-within': {
        outline: (theme) =>
          `max(3px, 0.08em) solid ${theme.palette.primary.main}`,
      },
    },

    '&:focus-within': {
      outline: (theme) =>
        `max(2px, 0.08em) solid ${theme.palette.primary.main}`,
    },

    '& > input': {
      display: 'grid',
      placeContent: 'center',
      appearance: 'none',
      justifySelf: 'end',
      width: 'var(--size-iconS)',
      height: 'var(--size-iconS)',
      m: 0,
      fontSize: (theme) => theme.spacing(0.625),

      color: 'neutral.light',
      border: '0.1em solid currentColor',
      borderRadius: '50%',
      backgroundColor: 'neutral.lightest',

      '&::before': {
        content: '""',
        width: 'var(--size-iconS)',
        height: 'var(--size-iconS)',
        borderRadius: '50%',
        color: 'primary.dark',
        border: '0.1em solid currentColor',
        backgroundColor: 'primary.main',
        transform: 'scale(0)',
        transition: '120ms transform ease-in-out',
      },
      '&:checked::before': {
        transform: 'scale(1)',
      },

      '&:focus': {
        outline: 'none',
      },

      '& .euro': {
        color: 'neutral.main',
        fontWeight: 'var(--typography-fontWeight-regular)',
        fontSize: 'var(--typography-typeScale-ml)',
      },
    },
  },
  // NOTE: These styles are needed at the time of this coding
  // since Firefox does not support :has() selector
  optionSelected: {
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    outline: (theme) => `max(2px, 0.08em) solid ${theme.palette.primary.main}`,

    '&:focus-within': {
      outline: (theme) =>
        `max(3px, 0.08em) solid ${theme.palette.primary.main}`,
    },
  },
}

export default styles
