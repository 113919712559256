import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  Box,
  Link as MUILink,
  IconButton,
  Button,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Checkbox,
  PrivacyPolicySummary,
} from '../../../../components/index.js'
import { Icons } from '../../../../../assets'
import { useTexts } from '../../../../../texts/index.js'
import styles from '../access.styles.js'

const userDataStepViewPropTypes = {
  email: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  onChangeControlledLegalCheckbox: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    conditionsAccepted: PropTypes.string,
    communicationsAccepted: PropTypes.string,
  }).isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    conditionsAccepted: PropTypes.string,
  }).isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

const UserDataStepView = ({
  email,
  formId,
  onSubmit,
  isSubmitting,
  ctaLabel,
  onChangeControlledLegalCheckbox,
  formValues,
  formError,
  fieldErrors,
  onChangeEmail,
}) => {
  const texts = useTexts()

  const [showPrivacyPolicySummary, setShowPrivacyPolicySummary] =
    useState(false)

  return (
    <>
      <Typography id='dialogTitle' sx={styles.heading}>
        {texts.getUserDataTitle()}
      </Typography>
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            id='email'
            name='email'
            defaultValue={email}
            label={texts.getLoginFormNameLabel()}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.Envelope />
                </InputAdornment>
              ),
            }}
            inputProps={{ inputMode: 'email' }}
            required
          />
          <Box sx={styles.groupFields}>
            <TextField
              id='name'
              name='name'
              label={texts.getNameLabel()}
              autoComplete='given-name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icons.User2 />
                  </InputAdornment>
                ),
              }}
              error={Boolean(fieldErrors.name)}
              required
            />
            <TextField
              id='familyName'
              name='familyName'
              label={texts.getFamilyNameLabel()}
              autoComplete='family-name'
              error={Boolean(fieldErrors.familyName)}
              required
            />
            {fieldErrors.name || fieldErrors.familyName ? (
              <Box sx={styles.groupErrors}>
                <Icons.ExclamationTriangle />
                {fieldErrors.name || fieldErrors.familyName}
              </Box>
            ) : null}
          </Box>
          <TextField
            id='phone'
            name='phone'
            label={texts.getPhoneLabel()}
            autoComplete='tel'
            type='tel'
            helperText={fieldErrors.phone}
            error={Boolean(fieldErrors.phone)}
            // NOTE: this field has been hidden temporarily due to a business decision,
            // and the easiest solution for avoiding updating the form validation/sending logic
            // is to hide the input with the help of styling
            sx={{ display: 'none' }}
          />
          {Boolean(formError) ? (
            <Alert variant='error' text={formError} />
          ) : null}
          <LoadingButton
            type='submit'
            color='secondary'
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
            fullWidth
            sx={styles.cta}
            endIcon={<Icons.ArrowRight />}
          >
            {ctaLabel ?? texts.getRegisterContinueAction()}
          </LoadingButton>

          <Box id='legal-checks'>
            <Box sx={styles.collapsible}>
              <Checkbox
                skin='light'
                label={
                  <>
                    {texts.getLegalAgreementLabel1()}
                    <MUILink
                      href='https://www.bepayper.com/terminos-y-condiciones'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementTermsLabel()}
                    </MUILink>
                    {texts.getAndLabel()}
                    <MUILink
                      href='https://www.bepayper.com/politica-de-privacidad'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementPrivacyPolicyLabel()}
                    </MUILink>
                    {texts.getLegalAgreementLabel2()}
                  </>
                }
                name='conditionsAccepted'
                checked={Boolean(formValues.conditionsAccepted)}
                onChange={onChangeControlledLegalCheckbox}
                error={
                  fieldErrors.conditionsAccepted &&
                  texts.getErrorConditionsAccepted()
                }
                required
              />
              <IconButton
                aria-label={texts.getCloseAction()}
                onClick={() => setShowPrivacyPolicySummary((value) => !value)}
                sx={styles.collapsibleButton}
              >
                {showPrivacyPolicySummary ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </Box>
            {showPrivacyPolicySummary && <PrivacyPolicySummary />}
            <Checkbox
              sx={{ mt: 1 }}
              label={texts.getLegalAgreementCommunicationsLabel()}
              name='communicationsAccepted'
              checked={Boolean(formValues.communicationsAccepted)}
              onChange={onChangeControlledLegalCheckbox}
            />
          </Box>

          <Button
            id='change-email-btn'
            variant='text'
            onClick={onChangeEmail}
            fullWidth
          >
            {texts.getChangeEmailAction()}
          </Button>
        </FormSkinWrapper>
      </form>
    </>
  )
}

UserDataStepView.propTypes = userDataStepViewPropTypes

export default UserDataStepView
