import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import { Icons } from '../../../../assets/index.js'
import { useTexts } from '../../../../texts/index.js'
import { formatNumber, formatShortDate, formatHour } from '../../../format.js'
import { Card } from '../../../components/index.js'
import * as PT from '../../../propTypes.js'

const styles = {
  expirationLabel: {
    '& > svg': {
      transform: 'translate(0, 2px)',
      mr: '3px',
    },
  },
}

const bonusCardPropTypes = {
  bonus: PT.Bonus,
  partnerName: PropTypes.string,
}

const BonusCard = ({ bonus, partnerName }) => {
  const texts = useTexts()
  return (
    <Card
      type='balance'
      variant='success'
      title={
        bonus.type === 'balance' ? formatNumber(bonus.detail.amount) : undefined
      }
      renderContent={() => (
        <>
          <Typography
            dangerouslySetInnerHTML={{
              __html: texts.getBonusCardLabel(partnerName),
            }}
          />

          <Typography className='highlighted' sx={styles.expirationLabel}>
            <Icons.Calendar />
            {texts.getExpirationLabel(
              formatShortDate(bonus.expiration_date),
              formatHour(bonus.expiration_date),
            )}
          </Typography>
        </>
      )}
    />
  )
}

BonusCard.propTypes = bonusCardPropTypes

export default BonusCard
