const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',
  },
  bankCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    backgroundColor: 'neutral.white',
  },
  wrapInputs: {
    display: 'grid',
    gridTemplateColumns: (theme) => `auto ${theme.spacing(7.5)}`,
    gap: {
      xs: 1,
      md: 4,
    },
  },
  groupInputs: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 0.25,

    // NOTE: We add this style exceptions instead of creating more exceptions within the markup
    '& > div:nth-of-type(1) iframe': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
    },
    '& > div:nth-of-type(2) iframe': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: (theme) => theme.shape.borderRadius,
      borderBottomLeftRadius: 0,
    },
    '& > div:nth-of-type(2) label': {
      left: (theme) => theme.spacing(1.5),
    },
  },
  logos: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    filter: 'grayscale(1)',
  },
  input: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
  },
  inputIcon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.25),
    color: 'neutral.dark',
    position: 'absolute',
    top: '50%',
    left: (theme) => theme.spacing(0.75),
    transform: 'translateY(-50%)',
  },
  inputLabel: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'neutral.dark',
    position: 'absolute',
    top: 12,
    left: 42,
  },
  inputStar: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(0.625),
    height: (theme) => theme.spacing(0.625),
    color: 'info.main',
    borderRadius: '50%',
    backgroundColor: 'neutral.light',
    position: 'relative',

    '&::before': {
      content: '"*"',
      transform: (theme) => `translateY(${theme.spacing(0.125)})`,
    },
  },
  inputIframe: {
    height: (theme) => theme.spacing(4),

    '& iframe': {
      borderRadius: (theme) => theme.spacing(0.5),
    },
  },
  submit: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
  },
  submitIcon: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
    fontSize: (theme) => theme.spacing(1.5),
    color: 'neutral.white',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  submitIconDefaultPosition: {
    left: '30%',
  },
  submitIframe: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
    transition: 'opacity 300ms',

    '&:hover': {
      opacity: 0.85,
    },
  },
  message: {
    position: 'relative',
    mt: 1,
  },
}

const iframedInputStyles =
  'display: flex; width: calc(100% + 16px); height: 52px; padding: 16px 0 0 44px; margin: -10px; border: 0; font-family: "Inter","Helvetica Neue",Arial,sans-serif; font-size: 14px; color: #121212; background: #F7F7F5;'

const iframedInputYearStyles =
  'display: flex; width: calc(100% + 16px); height: 52px; padding: 16px 0 0 24px; margin: -10px; border: 0; font-family: "Inter","Helvetica Neue",Arial,sans-serif; font-size: 14px; color: #121212; background: #F7F7F5;'

const iframedButtonStyles =
  'box-sizing: border-box; width: min(100%, 20rem); height: 59.6875px; display:flex; align-items:center; justify-content:center; border: 0; margin: -16px; margin-inline: auto; transform: translate(8px, 8px);padding: 20px 32px; border-radius: 8px; font-family: Inter, "Helvetica Neue", Arial, sans-serif; font-size: 14px; font-weight: 700; text-transform: none; text-decoration: none; line-height: 24.5px; color: #343434; box-shadow: none; -webkit-tap-highlight-color: transparent; background-color: #ffcc00; position: relative; transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; cursor: pointer; user-select: none; vertical-align: middle; -moz-appearance: none; -webkit-appearance: none; -webkit-text-decoration: none; @media (min-width: 601px) { width: 20rem;}'

const concatIframedButtonStyles = (styles) =>
  styles ? iframedButtonStyles.concat(styles) : iframedButtonStyles

export {
  styles,
  iframedInputStyles,
  iframedInputYearStyles,
  iframedButtonStyles,
  concatIframedButtonStyles,
}
