// Source: https://stackoverflow.com/a/106223
// Definition: https://man7.org/linux/man-pages/man7/hostname.7.html
const VALID_HOSTNAME_REGEXP =
  /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)*$/i

const isValidUrl = (value) => {
  if (!value) return false
  try {
    const url = new URL(value)
    const validProtocol = url.protocol === 'http:' || url.protocol === 'https:'
    const validHostname = VALID_HOSTNAME_REGEXP.test(url.hostname)
    return validProtocol && validHostname
  } catch (e) {
    return false
  }
}

export default isValidUrl
