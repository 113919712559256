import { useNavigate } from 'react-router-dom'

import { useTexts } from '../../../../texts'
import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { User } from '../../../../business'
import { AssociatedMedia } from '../../../components'
import { PageContent, PageBlock, WhiteBckgFullWidth } from '../../../Layout'
import { PAGE_CONTENT_MARGINS } from '../../../Layout/PageContent'
import useMediaButtons from '../../useMediaButtons'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import HeroPartner from './HeroPartner'
import Interests from './Interests'

const Discovery = () => {
  const navigate = useNavigate()

  const { partnerState } = User.useBonus()
  const { coreMedia, onClickMedia } = useMediaButtons()

  const handleShowAllMedias = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_SHOW_ALL_MEDIA,
        },
      }),
    )

    navigate('newspapers')
  }

  return (
    <View
      partnerData={partnerState}
      media={coreMedia}
      onClickMedia={onClickMedia}
      onClickShowAllMedias={handleShowAllMedias}
    />
  )
}

export const View = ({
  partnerData,
  media,
  onClickMedia,
  withoutAddToHomeWizard,
  onClickShowAllMedias,
}) => {
  const texts = useTexts()
  return (
    <PageContent sx={{ mt: 0 }}>
      <WhiteBckgFullWidth sx={{ py: PAGE_CONTENT_MARGINS.mt }}>
        <HeroPartner partnerData={partnerData} />
        <Search />
      </WhiteBckgFullWidth>
      <Interests partnerData={partnerData} />
      <PageBlock title={texts.newspapers()}>
        <AssociatedMedia
          media={media}
          onClickMedia={onClickMedia}
          onClickShowAll={onClickShowAllMedias}
        />
      </PageBlock>
      {!withoutAddToHomeWizard && <AddToHomeScreenWizard />}
      <Sections />
    </PageContent>
  )
}

export default Discovery
