import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Icons } from '../../../../assets'
import {
  LogoutDialog,
  PayperBanner,
  CreditCard,
  CircularProgress,
} from '../../../components'
import { PageContent } from '../../../Layout'
import * as PT from '../../../propTypes'
import styles from './account.styles'
import PersonalDataCard from './PersonalDataCard'
import BalanceCard from './BalanceCard'
import BonusCard from './BonusCard'

const Section = ({ title, children, sx = {} }) => {
  return (
    <Box sx={sx}>
      <Typography component='h2' variant='h4'>
        {title}
      </Typography>
      <Box sx={styles.sectionContent}>{children}</Box>
    </Box>
  )
}

const AccountPropTypes = {
  bankCardData: PT.BankCardData,
  balanceData: PT.BalanceData,
  userData: PT.UserData,
  bonusData: PT.BonusData,
  partnerName: PropTypes.string,
  onClickTopUp: PropTypes.func,
  onClickEditBankCard: PropTypes.func,
  onClickAddBankCard: PropTypes.func,
  onClickUpdatePersonalData: PropTypes.func,
  onClickUpdatePassword: PropTypes.func,
}

const Account = ({
  bankCardData,
  balanceData,
  userData,
  bonusData,
  partnerName,
  onClickTopUp,
  onClickEditBankCard,
  onClickAddBankCard,
  onClickUpdatePersonalData,
  onClickUpdatePassword,
}) => {
  const texts = useTexts()
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  if (bonusData.isLoading || balanceData.isLoading || bankCardData.isLoading)
    return <CircularProgress height='100vh' />

  return (
    <PageContent sx={{ mt: 0 }}>
      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
      />
      <PayperBanner className='full-bleed' />
      <Box sx={styles.root}>
        {Boolean(bonusData.data) ? (
          <Section title={texts.promotion()}>
            <BonusCard bonus={bonusData.data} partnerName={partnerName} />
          </Section>
        ) : (
          <>
            {!!balanceData.data && (
              <Section title={texts.balanceTopUp()}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: texts.balancePayperPolicy(),
                  }}
                />
                <BalanceCard
                  balance={balanceData.data}
                  onClickTopUp={
                    Boolean(bankCardData.data) ? onClickTopUp : undefined
                  }
                />
              </Section>
            )}
            {!!bankCardData.data && (
              <Section title={texts.bankCard()}>
                <CreditCard
                  bankCard={bankCardData.data}
                  onClickEditBankCard={onClickEditBankCard}
                  onClickAddBankCard={onClickAddBankCard}
                />
              </Section>
            )}
          </>
        )}

        {Boolean(userData) && (
          <Section title={texts.personalData()}>
            <PersonalDataCard
              userData={userData}
              onClickUpdatePassword={onClickUpdatePassword}
              onClickUpdatePersonalData={onClickUpdatePersonalData}
            />
          </Section>
        )}
      </Box>
      <Button
        sx={{
          width: 'var(--button-max-width)',
          alignSelf: 'center',
          mx: 'auto',
        }}
        id='logout'
        color='primary'
        onClick={() => setShowLogoutDialog(true)}
        startIcon={<Icons.SignOut />}
        fullWidth
      >
        {texts.getUserLogoutAction()}
      </Button>
    </PageContent>
  )
}

Account.propTypes = AccountPropTypes

export default Account
