import { useState } from 'react'
import PropTypes from 'prop-types'

import { Session, User } from '../../../../business'
import { BankCardRegistration } from '../../../components'
import { useTexts } from '../../../../texts'
import View from './View'
import SuccessView from './SuccessView'

const BankCardChangePropTypes = {
  variant: PropTypes.oneOf(['edition', 'introduction']).isRequired,
}

const BankCardChange = ({ variant }) => {
  const texts = useTexts()

  const COPIES_BY_TYPE = {
    edition: {
      title: texts.getUpdateBankCardTitleLabel(),
      successTitle: texts.getUpdateBankCardSuccessTitleLabel(),
      successSubtitle: texts.getUpdateBankCardSuccessMessageLabel(),
    },
    introduction: {
      title: texts.getIntroBankCardTitleLabel(),
      successTitle: texts.getIntroBankCardSuccessTitleLabel(),
      successSubtitle: texts.getIntroBankCardSuccessMessageLabel(),
    },
  }
  const { title, successTitle, successSubtitle } = COPIES_BY_TYPE[variant]

  const userSub = Session.getUserSub()

  const { state: bankCard, refetchBankCard } = User.useBankCard()

  const [showSuccess, setShowSuccess] = useState(false)

  const handleSuccess = async () => {
    await refetchBankCard()
    setShowSuccess(true)
  }

  return (
    <>
      {showSuccess ? (
        <SuccessView
          bankCard={bankCard}
          title={successTitle}
          subtitle={successSubtitle}
        />
      ) : Boolean(userSub) ? (
        <View title={title} bankCardData={bankCard.data}>
          <BankCardRegistration onSuccess={handleSuccess} sub={userSub} />
        </View>
      ) : null}
    </>
  )
}

BankCardChange.propTypes = BankCardChangePropTypes

export default BankCardChange
