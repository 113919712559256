import theme from '../../../theme'

const styles = {
  invisible: {
    display: {
      xs: 'none',
      md: 'inherit',
    },
    color: 'transparent',
  },
  root: {
    '--image-size': theme.spacing(5.875),

    '& .MuiCard-root, & .MuiCardActionArea-focusHighlight, &:hover, &:focus, &:active, &:focus-visible, &:focus-within, &:visited, &:target':
      {
        width: '100%',
        minHeight: (theme) => theme.spacing(9.75),
        backgroundColor: 'neutral.white',
        boxShadow: 1,
        borderRadius: 'var(--corners-l, 20px)',
        '&:hover': {
          boxShadow: 2,
          backgroundColor: 'neutral.white',
          borderRadius: 'var(--corners-l, 20px)',
        },
      },
  },
  card: {
    p: 'var(--spacing-m)',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 'var(--spacing-s)',
    '& > div:first-of-type': {
      flexGrow: 2,
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 'var(--spacing-m)',
  },
  articleImage: {
    '& > img': {
      borderRadius: 'var(--corners-m)',
      height: {
        xs: 70,
        md: 'var(--image-size)',
      },
      width: {
        xs: 70,
        md: 'var(--image-size)',
      },
      objectFit: 'cover',
    },
  },
  clampedTitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 4,
    overflow: 'hidden',
  },
  mediaImage: {
    color: 'neutral.main',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 0.5,
    height: (theme) => theme.spacing(1),
    '& > img': {
      height: '100%',
      objectFit: 'contain',
    },
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    color: 'neutral.main',

    '& > .separator': {
      mx: 0.5,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: 'neutral.main',
    },
  },
  freeLabel: {
    color: 'info.dark',
    fontWeight: 700,
  },
}

export default styles
