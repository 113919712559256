import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { Alert } from '../index'
import styles from './topUpSelectorCards.styles'

const DEFAULT_SELECTED_INDEX = 0

const TopUpSelectorCards = ({ options, selected, setSelected, sx }) => {
  const texts = useTexts()

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <Box component='fieldset' sx={styles.fieldset}>
        {options.map((amount, idx) => (
          <Box
            key={amount}
            sx={{
              ...styles.wrapOption,
              ...(selected === amount ? styles.optionSelected : null),
            }}
          >
            <Box component='label' sx={styles.option}>
              <input
                type='radio'
                name='topUpAmount'
                value={amount}
                defaultChecked={idx === DEFAULT_SELECTED_INDEX ? true : false}
                onChange={() => setSelected(amount)}
              />
              <Typography variant='h3'>
                {amount}
                <Typography component='span' className='euro'>
                  €
                </Typography>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <Alert text={texts.getBalanceTopUpAlertTextLabel()} />
    </Box>
  )
}

export default TopUpSelectorCards
