import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { openMediaLink } from '../../utils'
import { User } from '../../../business'
import { ArticlesList } from '../../components'

const TopicPage = () => {
  const { topicId } = useParams()

  const fetchArticlesByTopic = useMemo(
    () => async (pagination) =>
      await User.getArticlesForTopic(topicId, pagination),
    [topicId],
  )

  const articlesData = User.useArticlesList({
    fetchFn: fetchArticlesByTopic,
    withLastPageValidationHack: true,
  })

  const handleOpenArticle = (article) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          interface: 'interest',
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )

    openMediaLink(article.url, document.location.pathname)
  }

  return (
    <ArticlesList
      name='interest'
      onClickArticle={handleOpenArticle}
      {...articlesData}
    />
  )
}

export default TopicPage
