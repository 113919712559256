export { default as hasOnlyDigits } from './hasOnlyDigits'
export { default as hasOnlyLetters } from './hasOnlyLetters'
export { default as isChecked } from './isChecked'
export { default as isFilled } from './isFilled'
export { default as isPhoneMask } from './isPhoneMask'
export { default as isValidEmail } from './isValidEmail'
export { default as isValidUrl } from './isValidUrl'

export const pipeValidators =
  (...functions) =>
  (input) =>
    functions.reduce((acc, fn) => {
      if (acc === false) return acc
      return fn(input)
    }, input)
