import { useTheme, useMediaQuery } from '@mui/material'

import EVENTS_NAMES from '../../analytics/eventsNames.json'
import { openMediaLink } from '../utils'
import { ASSOCIATED_MEDIA_DATA } from '../constants'

const useMediaButtons = (customAnalyticsData = {}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const mobileCoreMedia = ASSOCIATED_MEDIA_DATA.filter(
    (media) => media.isCoreInMobile,
  )
  const desktopCoreMedia = ASSOCIATED_MEDIA_DATA.filter(
    (media) => media.isCoreInDesktop,
  )

  const handleOpenMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
          ...customAnalyticsData,
        },
      }),
    )

    openMediaLink(mediaData.domain, document.location.pathname)
  }

  return {
    media: ASSOCIATED_MEDIA_DATA,
    coreMedia: isDesktop ? desktopCoreMedia : mobileCoreMedia,
    onClickMedia: handleOpenMediaPage,
  }
}

export default useMediaButtons
