import { backofficeEndpoint } from '../../config'

export const sendEmail = async (emailData) => {
  const response = await fetch(`${backofficeEndpoint}/send-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ emailData }),
  })

  if (response.ok && response.status === 204) {
    return Promise.resolve()
  }
  if (!response.ok) {
    throw new Error('email could not be sent')
  }
}

export const getMediaProviders = async () => {
  try {
    const response = await fetch(`${backofficeEndpoint}/media-providers`)

    if (response.ok) {
      return await response.json()
    }
  } catch (error) {
    console.error(error)
  }
}

export const getMediaProvider = async (mediaProviderId) => {
  try {
    const response = await fetch(
      `${backofficeEndpoint}/media-providers/${mediaProviderId}`,
    )

    if (response.ok) {
      return await response.json()
    }
  } catch (error) {
    throw error
  }
}

export const getPartnerById = async (partnerId) => {
  const response = await fetch(`${backofficeEndpoint}/partners/${partnerId}`)

  if (response.ok) {
    return await response.json()
  }
  return null
}

export const getArticlesSections = async () => {
  try {
    const response = await fetch(`${backofficeEndpoint}/articles/sections`)

    if (response.ok) {
      return await response.json()
    }
  } catch (error) {
    console.error(error)
  }
}

export const getArticleDataByUrl = async (encodedUrl) => {
  const response = await fetch(
    `${backofficeEndpoint}/articles/url?encodedUrl=${encodedUrl}`,
  )

  if (response.ok) {
    return await response.json()
  } else {
    return null
  }
}
