import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import { CreditCard } from '../../../components'

const BankCardChangeView = ({ title, bankCardData, children }) => {
  const texts = useTexts()
  return (
    <Box className='secondary-page-content' sx={{ gap: 2 }}>
      {bankCardData ? (
        <Box>
          <Typography variant='subtitle1'>
            {texts.getIntroBankCardCurrentCard()}
          </Typography>

          <CreditCard bankCard={bankCardData} />
        </Box>
      ) : null}
      <Box sx={{}}>
        <Typography variant='subtitle1'>{title}</Typography>
        {children}
      </Box>
    </Box>
  )
}

export default BankCardChangeView
